window.addEventListener('DOMContentLoaded', function () { // $('document').ready(function () {

    for (
        var e=["a","A","i","I","o","O","u","U","w","W","z","Z","e","E","aĹź","AĹź","bo","Bo","by","By","iĹź","IĹź","ni","Ni","Ĺźe","Ĺťe","do","Do","ku","Ku","na","Na","od","Od","po","Po","we","We","za","Za","ze","Ze","hÄ","HÄ","no","No","ot","Ot","aa","Aa","aj","Aj","am","Am","au","Au","ba","Ba","ee","Ee","eh","Eh","ej","Ej","fe","Fe","fi","Fi","fi","Fi","fu","Fu","ha","Ha","he","He","ho","Ho","ii","Ii","oj","Oj","oo","Oo","ot","Ot","oĹź","OĹź","pa","Pa","uf","Uf","uu","Uu","co","Co","ki","Ki","se","Se","ja","Ja","ty","Ty","ci","Ci","on","On","mu","Mu","go","Go","oĹ","OĹ","jÄ","JÄ","my","My","wy","Wy","im","Im","je","Je","ma","Ma","mÄ","MÄ","me","Me","Ăłw","Ăw","ta","Ta","tÄ","TÄ","tÄ","TÄ","to","To","te","Te","tu","Tu","al","Al","ar","Ar","as","Aa","at","At","bi","Bi","er","Er","es","Es","ew","Ew","ez","Ez","go","Go","id","Id","iĹ","IĹ","iw","Iw","li","Li","ok","Ok","om","Om","op","Op","or","Or","os","Os","oĹ","OĹ","oz","Oz","Ăłd","Ăd","Ăłs","Ăs","su","Su","ud","Ud","ul","Ul","ut","Ut","je","Je","ma","Ma","sÄ","SÄ","lub","Lub","ale","Ale","czy","Czy","nad","Nad","pod","Pod","bez","Bez","nie","Nie","tak","Tak","albo","Albo","wiÄc","WiÄc","lecz","Lecz","przez","Przez","niech","Niech","tylko","Tylko", "St.", "DJ"],
            o = 0,
            a = e.length;
        o < a;
        o++
    ) {
        var i = e[o];

        // String.fromCharCode(160) - is the &nbsp; representation
        Array.prototype.forEach.call(
            document.querySelectorAll('p'),
            function (x) {
                if (x.textContent.indexOf(" "+i+" ") !== -1)
                    x.textContent = x.textContent.replaceAll(
                        new RegExp(" " + i + " ", "g"), " " + i + String.fromCharCode(160)
                        );
            }
        );
    }
});


var image_active = document.querySelector('.image.active');
var image_passive = document.querySelector('.image.active.hide');
var image_switch = document.querySelector('.img-toggle');
image_switch.onclick = function () {
    image_active.classList.toggle('hide');
    image_passive.classList.toggle('hide');
}


